// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import i18n from "@/plugins/i18n";

export function preparedData(){
    return {
        paginated: { page: 1, limit: 25 } as Paginated,
        filters: {},
        options: getDefaultSortingOption("id"),
        table: {
            headers: [],
            items: [],
        },
        fields:{}
    }
};