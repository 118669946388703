import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Buttons from "../../Commons/Buttons.vue";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption, ResultPaginate } from "@/interfaces/paginated";
// @ts-ignore
import { isUndefined, isNull, isEmpty } from "lodash";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import CustomizeRowObjectGrid from "@/components/Header/Tables/CustomizeRowObjectGrid.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { preparedData } from "@/views/Admin/Lookalike/List/utils";
// @ts-ignore
import { 	
    prepareTableData,
	prepareSelectedData,
	prepareTableContent,
	preparedColumnDefsGrid 
} from "@/utils/CustomizeViewData";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
import omit from 'lodash/omit';
//@ts-ignore
import { getPermisionLookalike } from "@/utils/permissionResolve";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
    name: "ListLookalike",
    props: {},
    components: {
        Buttons,
        Alertize,
        GridTable,
        ActionsTable,
        FilterGridAG,
        CustomizeRowObjectGrid,
        NotPermission
    },
    data: () => ({
        dataEntity: preparedData(),
        title: "List Lookalike",
        context: null,
        selectionRows: "single",
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            await this.getPaginated();
        });
        this.context = { componentParent: this };
    },
    computed:{
        ...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
        ...mapGetters("profile", ["getAbility"]),

        ...mapGetters("internationalization", ["getLanguage"]),

        getResultPaginate(): ResultPaginate {
            return this.$store.state.lookalike.result_paginate;
        },

        getEntities(): any[] {
            const result: ResultPaginate = this.getResultPaginate;
            if (
                isUndefined(result) ||
                isNull(result) ||
                isUndefined(result.data) ||
                isNull(result.data)
            ) {
                return [];
            }
            return result.data;
        },

        getConfigColumnDef(){
            return {
                context: this.context,
                entityName: "Lookalike",
                eventActions: false,
                flex: 1,
                resizableID: true,
                checkStatus: false,
                minWidthActions: 170,
                maxWidthActions: 170,
                permission: this.getPermission
            };
        },

        getColumnsDef(){
            return preparedColumnDefsGrid(this.dataEntity.table.headers, this.getConfigColumnDef);
        },

        getRowsData(){
            if(isEmpty(this.dataEntity.table.items)) return [];
            return this.dataEntity.table.items;
        },

        getCountData() {
            return this.$t("show.lookalike", {
                total: this.getEntities.length,
            });
        },

        canList(){
            return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
        },

        getPermission(){
            return getPermisionLookalike();
        },

        getConfig() {
			return {
				paginated: this.dataEntity.paginated,
				filters: this.dataEntity.filters,
				options: this.dataEntity.options,
				fields: this.dataEntity.fields,
			};
		},
    },
    methods:{

        ...mapActions("loading", ["setLoadingData"]),
        ...mapActions("lookalike", ["paginated"]),

        ...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected"
		]),

		...mapActions("customizer_filter", [
			"saveFiltersEntity",
			"resetFilterEntity",
		]),

        updatePaginate(data: any) {
            this.dataEntity.paginated.page = data;
        },

        setFilter(params: { key: string | number; value: any }) {
            this.dataEntity.filters = {};
            this.dataEntity.filters[params.key] =
                typeof params.value !== "undefined" ? params.value : "";
        },

        async getPaginated(uTable: boolean = true) {
            if(this.canList){
                this.setLoadingData(TypeLoading.loading)
                await this.paginated(
                    await ParamService.getParams(
                        this.dataEntity.paginated,
                        this.dataEntity.filters,
                        this.dataEntity.options
                    ));
                if (uTable) {
                    await this.getTableData({ view: this.$route?.name });
                } else {
                    await this.updateItemsTable();
                }
                await this.setLoadingData();
            }
        },

        async selectedLimit(limit: number) {
            this.dataEntity.paginated.limit = limit;
            this.updatePaginate(1);
            await this.getPaginated(false);
        },

        async removeOption() {
            this.dataEntity.filters = {};
            this.dataEntity.options = getDefaultSortingOption("id");
            this.updatePaginate(1);
            await this.getPaginated(false);
        },

        async removeKeyFilter(key: string) {
            const newFilters: any = omit(this.dataEntity.filters, [key]);
            await this.updateParams({
                filters: newFilters,
                options: this.dataEntity.options,
            });
        },

        async selectedOption(params: {
            options: SortingOption;
            filter: string;
        }) {
            this.setFilter({ key: params.options.sort, value: params.filter });
            this.updatePaginate(1);
            await this.updateParams({
                filters: this.dataEntity.filters,
                options: params.options
            });
        },

        async updateParams(params: { filters: any; options: SortingOption;})
        {
            this.dataEntity.filters = params.filters;
            this.dataEntity.options = params.options;
            this.updatePaginate(1);
            await this.getPaginated(false);
        },

        async updateItemsTable() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);

			if(!isEmpty(selected)){
				this.dataEntity.fields = await prepareSelectedData(selected);

				this.dataEntity.table.items = await prepareTableContent(
					this.dataEntity.fields,
					this.getEntities
				);
				this.$forceUpdate();
			}

		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {

			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getEntities,
			});
    
			this.dataEntity.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.dataEntity.table = await prepareTableData({
				columns: columns,
				fields: this.dataEntity.fields,
				entity: "lookalike",
				entities: this.getEntities,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.dataEntity.table.headers });
				//this.columnRestored = event?.isReset
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

        async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.dataEntity.table.headers });
			await this.getTableData(event);
		},

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

    },
    watch: {
        "dataEntity.paginated.page"(val, old) {
            if (val !== old) {
                this.getPaginated(false);
            }
        }
    }

});